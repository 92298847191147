const axios = require('axios');

let ALGOLIA_EVENTS_URL;
const sendEventToApi = (body) => {
  axios.post(ALGOLIA_EVENTS_URL, body);
};

function initAlgoliaEventsUrl(url) {
  ALGOLIA_EVENTS_URL = url;
}

function sendViewedObjectsId(payload) {
  if (!payload.index) return;

  const body = {
    events: [
      {
        eventType: 'view',
        eventName: 'Products Viewed',
        index: payload.index,
        objectIDs: payload.objectIDs,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };

  sendEventToApi(body);
}

function sendViewedFilters(payload) {
  //     "filters": ["color:red", "discount:10%25"],
  if (!payload.index) return;

  const body = {
    events: [
      {
        eventType: 'view',
        eventName: 'Filters Viewed',
        index: payload.index,
        filters: payload.filters,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };

  sendEventToApi(body);
}

function sendClickedObjectIDsAfterSearch(payload) {
  if (!payload?.queryID || !payload.index) return;

  const body = {
    events: [
      {
        eventType: 'click',
        eventName: 'Products Clicked After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        positions: payload.positions,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };
  sendEventToApi(body);
}

function sendAddedToCartObjectIDsAfterSearch(payload) {
  if (!payload?.queryID || !payload.index) return;

  //     "objectData": [
  //       {
  //         "price": 29.99,
  //         "discount": 10.00,
  //         "quantity": 2
  //       }
  const body = {
    events: [
      {
        eventType: 'conversion',
        eventSubtype: 'addToCart',
        eventName: 'Products Added to Cart After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        objectData: payload.objectData,
        value: payload.value,
        currency: payload.currency,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };
  sendEventToApi(body);
}

function sendClickedFilters(payload) {
  //     "filters": ["color:red"],
  if (!payload?.index) return;

  const body = {
    events: [
      {
        eventType: 'click',
        eventName: 'Filters Clicked',
        index: payload.index,
        filters: payload.filters,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };
  sendEventToApi(body);
}

function sendConvertedFilters(payload) {
  //     "filters": ["size:large"],
  if (!payload?.index) return;

  const body = {
    events: [
      {
        eventType: 'conversion',
        eventName: 'Filters Converted',
        index: payload.index,
        filters: payload.filters,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };
  sendEventToApi(body);
}

function sendPurchaseAfterSearch(payload) {
  //     "objectData": [
  //       {
  //         "queryID": "43b15df305339e827f0ac0bdc5ebcaa7",
  //         "price": 29.99,
  //         "discount": 10.00,
  //         "quantity": 2
  //       }
  //     ],
  //   }

  const body = {
    events: [
      {
        eventType: 'conversion',
        eventSubtype: 'purchase',
        eventName: 'Products Purchased After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        objectData: payload.objectData,
        value: payload.value,
        currency: payload.currency,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };
  sendEventToApi(body);
}

function sendConvertedObjectIdsAfterSearch(payload) {
  if (!payload?.queryID || !payload.index) return;

  const body = {
    events: [
      {
        eventType: 'conversion',
        eventName: 'Items Converted After Search',
        index: payload.index,
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        userToken: payload.userToken,
        authenticatedUserToken: payload.authenticatedUserToken,
        timestamp: Date.now(),
      },
    ],
  };
  sendEventToApi(body);
}

function setProductDataLayerData(datalayer, query_id, index_name, objectId) {
  datalayer.search_engine = {
    identifier: 'algolia',
    field: {
      query: query_id,
    },
    indexable_name: index_name,
    object_id: objectId,
  };

  return datalayer;
}

module.exports = {
  sendViewedObjectsId,
  sendViewedFilters,
  sendClickedObjectIDsAfterSearch,
  sendAddedToCartObjectIDsAfterSearch,
  sendClickedFilters,
  sendConvertedFilters,
  sendPurchaseAfterSearch,
  sendConvertedObjectIdsAfterSearch,
  initAlgoliaEventsUrl,
  setProductDataLayerData,
};
